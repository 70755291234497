import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { graphql } from 'gatsby'
import Layout from '../Layout'
import AllPosts from '../AllPosts'
import PropTypes from 'prop-types'

const styles = theme => ({
    section: {
        marginTop: theme.spacing.unit * 3
    },
    toolbar: {
        marginTop: theme.spacing.unit * 2
    },
    button: {
        marginRight: theme.spacing.unit
    }
})

const IndexPage = ({ pageContext, data }) => {
    return (
        <Layout>
            <AllPosts data={data} context={pageContext} />
        </Layout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
}

export default withStyles(styles)(IndexPage)

export const pageQuery = graphql`
  query ($limit: Int!, $skip: Int!){
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          timeToRead
          frontmatter {
            title
            date(formatString: "MMM Do YYYY")
            path
            categories
            tags
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 600,
                  duotone: {
                    highlight: "#A20471",
                    shadow: "#40C4FF",
                    opacity: 20
                  }, quality: 100, cropFocus: CENTER) {
                    base64
                    src
                    srcSet
                    sizes
                    aspectRatio
                    originalImg
                }
              }
            }
          }
        }
      }
    }
  }
`
